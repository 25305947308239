import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { FormSelectInput } from "../../components";
const DivvyVirtualCard = () => {
  const navigate = useNavigate();
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [budgetList, setBudgetList] = useState([]);
  const [selectedBudget, setSelectedBudge] = useState("QnVkZ2V0OjY1NTQ4Mw==");

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, minWidth: 180 },
    { headerName: "Card Holder", field: "name", flex: 1 },
    {
      field: "Cards",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params?.row?.userId ? (
          <span
            onClick={() => openCards(params.row.userId)}
            className="text-blue-600 cursor-pointer hover:underline"
          >
            All Cards
          </span>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const openCards = (recordId) => {
    navigate(`/settings/bill_cards/${recordId}`, {
      state: {
        budgetId: selectedBudget,
      },
    });
  };
  const getBudgetList = () => {
    setIsLoading(true);
    api
      .get(`/api/bill/get_budgets`)
      .then((res) => setBudgetList(res.data?.results))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  const getBudgetUsers = async (budgetId) => {
    setIsLoading(true);
    try {
      const res = await api.get(`api/bill/get_budget_users/${budgetId}`);
      if (res.status === 200 || res.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getBudgetList();
    getBudgetUsers(selectedBudget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listing = record?.results?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleBudgetChange = (value) => {
    setSelectedBudge(value);
    getBudgetUsers(value);
  };
  return (
    <>
      <PageHeader heading="Cards Listing" />
      <div className="bg-white my-3 border rounded">
        <div
          className="flex items-center justify-between bg-slate-100 text-gray-700 text-xl font-medium px-4 py-3"
          role="alert"
        >
          <p>
            {budgetList?.length
              ? budgetList?.find((item) => item.id === selectedBudget)?.name
              : ""}
          </p>
          <div className="text-right w-[250px]">
            <FormSelectInput
              name="budgetType"
              label="Budget List"
              options={budgetList}
              labelProp="name"
              valueProp="id"
              onChange={handleBudgetChange}
              value={selectedBudget || ""}
            />
          </div>
        </div>

        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = index + 1;
            const { userId, name, budgetRole } = record;
            return {
              counter,
              records: { ...record?.node },
              userId,
              name,
              budgetRole,
            };
          })}
          totalItems={listing?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          showCount="No"
        />
      </div>
    </>
  );
};

export default DivvyVirtualCard;
