import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";
import { FormInput, Button } from "../../../../components";
import { Button as MuiButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaSearch, FaUserPlus } from "react-icons/fa";
import {
  handle_tech,
  job_call_logs,
  notify_tech,
} from "../../../../features/jobsSlice";
import { call_by_link } from "../../../../features/dialerSlice";
import errorMessage from "../../../../util/errorMessage";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Dropdown } from "react-bootstrap";
import {
  get_tech,
  get_vendors_for_jobs,
} from "../../../../features/usersSlice";
import { sendIframeMsg } from "../../../../util/iFrame";
import Avatar from "../../../../components/atoms/Avatar";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import CustomModal from "./CustomModal";
function AssignTechSection(props) {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const industry = useSelector((state) => state.industry);
  const { formik, tech, jobTypes, job_id } = props;
  const dispatch = useDispatch();
  const [isModal, setIsModal] = React.useState(false);
  const dialer = useSelector((state) => state.dialer);
  const { techs, isLoader } = useSelector((state) => state.users);
  const [errors, setErrors] = React.useState([]);
  const [filter, setFilter] = useState({
    industry_id: formik.values.industry_id?._id
      ? formik.values.industry_id?._id
      : "",
    radius: "",
    zip: formik.values?.pickup_zip ? formik.values?.pickup_zip : "",
  });
  const [type, setType] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const [techUserId, setTechUserId] = useState(null);
  const [isShowDeail, setIsShowDetail] = useState(false);

  const [vicidialId, setVicidialId] = React.useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const { industryDrd } = useSelector((state) => state.industry);
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      if (document.visibilityState === "visible") {
        setVicidialId(event?.data?.vicidial_id);
      }
    }
  };

  window.addEventListener("message", receiveMessage);
  React.useEffect(() => {
    if (vicidialId && formik.values.id && type && techUserId) {
      dispatch(
        job_call_logs({
          lead_id: formik.values.id,
          type: type,
          tech_user_id: techUserId,
          vicidial_id: vicidialId,
          call_type: "Outgoing",
        })
      );
      setType(null);
      setTechUserId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vicidialId]);

  React.useEffect(() => {
    if (job_id) {
      dispatch(
        get_tech({
          page: 1,
          size: paginationModel.pageSize,
          job_id: job_id,
        })
      );
      dispatch(get_industry_drd());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callOn = async (number) => {
    if (dialer?.status === "PAUSE") {
      try {
        const res = await dispatch(call_by_link(number));
        if (res?.payload?.status === 200) {
          toast.success("Call Successfully");
          setType("Tech");
          setTimeout(() => {
            sendIframeMsg({
              action: "send_vendor_lead_code",
              user: user?.dialer_data?.dialer_user,
              pass: user?.dialer_data?.dialer_pass,
              vendor_lead_code: formik?.values?.lead_num,
            });
          }, 5000); // 5 seconds timeout
        }
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };
  const handleTech = async (tech_id) => {
    const c = window.confirm(
      tech_id === tech?._id
        ? "Are you sure want to unassign this technician?"
        : "Are you sure want to assign this technician?"
    );
    if (c) {
      try {
        const res = await dispatch(
          handle_tech({
            id: formik?.values._id,
            tech_user_id: tech_id === tech?._id ? null : tech_id,
          })
        );
        if (res?.payload?.status === 200) {
          formik?.setFieldValue("vendor_id", res?.payload?.data?.vendor_id);
          formik?.setFieldValue("tech_user_id", res?.payload?.data?.vendor_id);
          if (res?.payload?.data?.vendor_id) {
            toast.success("Technician assigned successfully");
            setIsModal(true);
          } else {
            toast.success("Technician remove successfully");
          }
          const payload = {
            page: 1,
            job_id: job_id,
            size: paginationModel.pageSize,
          };
          if (queryOptions.rules.length > 0) {
            payload.filters = queryOptions;
          } else {
            delete payload.filters;
          }
          dispatch(get_tech(payload));
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Technician",
            msg: "assigned",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Technician",
          msg: "assigned",
        });
      }
    }
  };
  const notifyTechHandler = async (notifyBy) => {
    try {
      const res = await dispatch(
        notify_tech({ job_id: formik?.values._id, notify_by: notifyBy })
      );
      if (res?.payload?.status === 200) {
        toast[res.payload.data?.status ? "success" : "error"](
          Array.isArray(res.payload.data.message)
            ? res.payload.data.message[0]
            : res.payload.data.message
        );
        if (isModal) {
          setIsModal(false);
        }
      } else {
        errorMessage({
          payload: res.payload,
          action: "Notification",
          msg: "sent",
        });
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:157 ~ notifyTechHandler ~ err:", err);
      errorMessage({ payload: err, action: "Notification", msg: "sent" });
    }
  };
  /*  const filterTechs = techs?.filter((e) => {
    const {
      role,
      first_name,
      last_name,
      address_latitude,
      address_longitude,
      phone,
      zip,
      city,
    } = e;
    const searchTextLowerCase = searchText?.toLowerCase();
    const containsSearchText = [
      first_name,
      last_name,
      address_latitude,
      address_longitude,
      phone,
      zip,
      city,
    ].some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTextLowerCase);
      }
      return false;
    });
    return containsSearchText || role?.name.includes(searchText);
  }); */
  React.useEffect(() => {
    if (formik.values?.pickup_zip || formik.values.industry_id) {
      setFilter({
        ...filter,
        industry_id: formik.values.industry_id?._id,
        zip: formik.values?.pickup_zip,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.pickup_zip, formik.values.industry_id?._id]);
  const handleGetVendors = async () => {
    if (!filter.industry_id) {
      return toast.error("Industry is required");
    }
    try {
      const res = await dispatch(
        get_vendors_for_jobs({
          industry_id: filter.industry_id,
          radiusInMeters: Math.round(filter.radius * 1609),
          zip: filter.zip,
          // job_id:id
        })
      );
      if (res?.payload?.status !== 200) {
        errorMessage({
          payload: res.payload,
          setErrors: setErrors,
          action: "Vendors",
          msg: "loaded",
        });
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:90 ~ handleGetVendors ~ err:", err);
      errorMessage({
        payload: err.payload,
        setErrors: setErrors,
        action: "Vendors",
        msg: "loaded",
      });
    }
  };
  const sortedIndustries = [...industry?.industryDrd].sort((a, b) =>
    a?.name.localeCompare(b?.name)
  );

  function calculateDistanceInMiles(lat1, lon1, lat2, lon2) {
    if (!lat1 || !lat2 || !lon1 || !lon2) {
      return;
    }
    const R = 3958.8; // Earth's radius in miles
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Difference in latitude converted to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180); // Difference in longitude converted to radians

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in miles

    return distance?.toFixed(2);
  }

  // function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
  //   const R = 6371; // Radius of the earth in km
  //   const dLat = deg2rad(lat2 - lat1);
  //   const dLon = deg2rad(lon2 - lon1);
  //   const a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
  //     Math.sin(dLon / 2) * Math.sin(dLon / 2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   const d = R * c; // Distance in km
  //   const miles = d / 1.609344; // Convert km to miles
  //   return miles.toFixed(2);
  // }

  // function deg2rad(deg) {
  //   return deg * (Math.PI / 180)
  // }
  const [idsArray, setIdsArray] = useState([]);
  const TagsCellRenderer = (data) => {
    const values = idsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag?.name}
        {/* {industry?.record?.find(({ id }) => id === tag?.id)?.industry_name} */}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length > 1 ? (
          !idsArray?.includes(data?.row?._id) ? (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() => setIdsArray([...idsArray, data?.row?._id])}
            >
              {`See More (${data?.value?.length - 1})`}
            </div>
          ) : (
            <div
              className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
              onClick={() =>
                setIdsArray((preArray) => {
                  if (!preArray || !data?.row?._id) return preArray;
                  return preArray.filter((id) => id !== data.row._id);
                })
              }
            >
              {`See Less`}
            </div>
          )
        ) : null}
      </div>
    );
  };
  let techRecords = techs?.records?.filter((data) =>
    data?.first_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      headerName: "Action",
      field: "actions",
      renderCell: (params) => {
        return params.row.records ? (
          <div>
            <FaUserPlus
              onClick={() => handleTech(params.row._id)}
              className="my_navIcon"
              title="Assign Technician"
            />
          </div>
        ) : null;
      },
      minWidth: 80,
      filterable: false,
    },
    {
      headerName: "First Name",
      field: "first_name",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return params?.row?.first_name ? (
          <div
            style={{
              cursor: `${params.row._id === tech?._id ? "" : "pointer"}`,
            }}
            onClick={() => handleTech(params.row._id)}
          >
            {params.row._id === tech?._id ? (
              <span className="mr-2">✓</span>
            ) : null}
            {params.row.first_name}
          </div>
        ) : null;
      },
    },
    {
      headerName: "Last Name",
      field: "last_name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return params?.row?.last_name ? (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleTech(params.row._id)}
          >
            {params.row.last_name}
          </div>
        ) : null;
      },
    },
    {
      headerName: "Phone",
      field: "phone",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return params?.row?.phone ? (
          <div className="text-center flex border-b-[#fff] justify-between items-center w-fit">
            {params?.row?.phone}
            <FaPhone
              className="ml-2 cursor-pointer"
              onClick={() => {
                setTechUserId(params?.row?._id);
                callOn(params?.row?.phone);
              }}
            />
          </div>
        ) : null;
      },
    },
    {
      headerName: "Zip",
      field: "zip",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return params?.row?.zip ? (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleTech(params.row._id)}
          >
            {params.row.zip}
          </div>
        ) : null;
      },
    },
    { headerName: "Job Count", field: "job_count", flex: 1, minWidth: 120 },
    {
      headerName: "Industry",
      field: "industry_ids",
      flex: 1,
      renderCell: TagsCellRenderer,
      minWidth: 150,
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params?.row?.industry_ids,
      valueFormatter: (params) => params.value,
    },
    { headerName: "Email", field: "email", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city", flex: 1, minWidth: 100 },
    { headerName: "State", field: "state", flex: 1, minWidth: 100 },
    {
      headerName: "Tech(%)",
      field: "tech_percentage",
      flex: 1,
      minWidth: 100,
      type: "number",
    },
    {
      headerName: "Distance",
      field: "",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => {
        return params.row?.address_latitude && params.row?.address_longitude ? (
          <div>
            {calculateDistanceInMiles(
              +formik?.values?.pickup_latitude,
              +formik?.values?.pickup_longitude,
              +params.row?.address_latitude,
              +params.row?.address_longitude
            )}
          </div>
        ) : null;
      },
    },
    {
      headerName: "Masking",
      field: "client_phone_masking",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => {
        return params.row?.client_phone_masking ? (
          <div>{"Yes"}</div>
        ) : (
          <span>{"No"}</span>
        );
      },
    },
  ];
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    const payload = {
      page: +params.page + 1,
      job_id: job_id,
      size: params.pageSize,
    };
    if (queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    } else {
      delete payload.filters;
    }
    dispatch(get_tech(payload));
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      const payload = {
        page: 1,
        job_id: job_id,
        size: paginationModel.pageSize,
      };
      if (queryOptions.rules.length > 0) {
        payload.filters = queryOptions;
      } else {
        delete payload.filters;
      }
      dispatch(get_tech(payload));
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    queryOptions.rules.forEach((rule) => {
      if (rule.field === "tech_percentage") {
        rule.field = "tech_percentage";
        rule.data = parseInt(rule.data);
      }
      if (rule.field === "phone") {
        rule.field = "phone";
        rule.data = parseInt(rule.data);
      }
    });
    const payload = {
      page: 1,
      job_id: job_id,
      size: paginationModel.pageSize,
    };
    if (queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    } else {
      delete payload.filters;
    }
    dispatch(get_tech(payload));
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MuiButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </MuiButton>
      </GridToolbarContainer>
    );
  }
  const industryIdArray = [];
  const getRowHeight = (params) => {
    const rowHeight = 40;
    const cellContentHeight = 0;
    const data = params?.model || {};
    const industryIdsLength = data.industry_ids ? data.industry_ids.length : 0;
    const addressHeight = rowHeight + industryIdsLength * 20;
    if (data.industry_ids && industryIdsLength > 1) {
      if (
        !idsArray?.includes(data._id) &&
        !industryIdArray?.includes(data._id)
      ) {
        return rowHeight + 15;
      }
      const tagHeight = 25;
      if (
        addressHeight >
        rowHeight + tagHeight * industryIdsLength + cellContentHeight
      ) {
        return addressHeight;
      } else if (
        idsArray?.includes(data._id) &&
        !industryIdArray?.includes(data._id)
      ) {
        return rowHeight + tagHeight * industryIdsLength + cellContentHeight;
      } else if (
        !idsArray?.includes(data._id) &&
        industryIdArray?.includes(data._id)
      ) {
        return rowHeight + tagHeight + cellContentHeight;
      } else {
        return rowHeight + tagHeight * industryIdsLength + cellContentHeight;
      }
    }

    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };

  const getRowClassName = (params) => {
    if (params && params.row._id === tech?._id) {
      return "bg-yellow-500";
    }
  };
  const getCellClassName = (params) => {
    if (params && params.row._id === tech?._id) {
      return "bg-yellow-500";
    }
    return "";
  };
  return (
    <div className="px-2 !mt-4">
      {isModal ? (
        <CustomModal
          onClose={() => setIsModal(false)}
          notifyTechHandler={notifyTechHandler}
        />
      ) : null}
      <div className="bg-white border px-3 md:px-4 rounded">
        <div className="md:col-4 pl-2 pt-3 !mb-2">
          <h5 className="">Assign Tech</h5>
        </div>
        <div className="flex flex-col gap-3 lg:gap-0 lg:grid grid-cols-12 mb-2">
          <div className="col-span-2 relative">
            <label
              htmlFor={"industry"}
              className={[
                `z-10 small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] bg-white scale-75 px-1`,
              ].join(" ")}
            >
              {"Job Industry"}
            </label>
            <PrimeDropdown
              value={filter.industry_id}
              onChange={(e) => setFilter({ ...filter, industry_id: e.value })}
              options={[
                { name: "Select Industry", _id: "", id: "" },
                ...sortedIndustries,
              ]}
              optionLabel="name"
              optionValue="_id"
              placeholder="Select Industry"
              className="relative h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
              name="industry"
            />
          </div>

          <div className="col-span-2 relative">
            <label
              htmlFor={"job-category"}
              className={[
                `z-10 small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] -translate-y-[12px] bg-white scale-75 px-1`,
              ].join(" ")}
            >
              {"Job Category"}
            </label>
            <input
              type="text"
              className="!text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="job-category"
              placeholder="Job Category"
              value={
                jobTypes?.record?.records?.find(
                  ({ _id }) => formik.values.job_category_id === _id
                )?.name
              }
              name="job-category"
              readOnly
            />
          </div>

          <div className="col-span-2 relative">
            <label
              htmlFor={"zip-codes"}
              className={[
                `z-10 small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] -translate-y-[12px] bg-white scale-75 px-1`,
              ].join(" ")}
            >
              {"Customer Zip"}
            </label>
            <input
              type="text"
              className="!text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="zip-codes"
              placeholder="Zip"
              onChange={(e) => setFilter({ ...filter, zip: e.target.value })}
              value={filter.zip}
              name="zip-codes"
            />
          </div>
          <div className="col-span-2 relative">
            <label
              htmlFor={"tech_radius"}
              className={[
                `z-10 small text-[rgba(0,_0,_0,_0.6)] absolute -left-[10px] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] -translate-y-[12px] bg-white scale-75 px-1`,
              ].join(" ")}
            >
              {"Tech Radius from Customer Zip"}
            </label>
            <input
              type="text"
              className="!text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="tech_radius"
              placeholder="Radius in Miles"
              onChange={(e) => setFilter({ ...filter, radius: e.target.value })}
              value={filter.radius}
              name="tech_radius"
              onBlur={() =>
                filter.radius
                  ? null
                  : dispatch(
                      get_tech({
                        page: 1,
                        size: paginationModel.pageSize,
                        job_id: formik.values._id,
                      })
                    )
              }
            />
          </div>
          <div className="col-span-4 md:pl-2 flex flex-col gap-2 md:flex-row">
            <Button
              text="Search Vendors"
              variant="btn_submit"
              onClick={handleGetVendors}
              className="w-full md:w-auto"
            />
            <div className="relative md:ml-2">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="rounded-0 w-full md:w-auto py-1.5 px-3 align-middle !border-primary-100 !bg-primary-100 focus:!shadow-none"
                >
                  Send
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-auto w- ">
                  <Dropdown.Item onClick={() => notifyTechHandler("Email")}>
                    Email
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => notifyTechHandler("SMS")}>
                    SMS
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => notifyTechHandler("Both")}>
                    Both
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Button
              text="Reset Search"
              className="btn md:ml-2 h-[38px] !rounded-none"
              onClick={() =>
                filter.radius
                  ? null
                  : dispatch(
                      get_tech({
                        page: 1,
                        size: paginationModel.pageSize,
                        job_id: formik.values._id,
                      })
                    )
              }
              color="#fff"
              variant="btn_cancel"
            />
          </div>
        </div>

        <div className="ml-1 items-center flex flex-col md:flex-row">
          <div className="flex items-center">
            {formik.values.vendor_id && (
              <>
                <div className="align-self-center flex">
                  <Avatar
                    name={formik.values.vendor_id?.first_name}
                    size={32}
                  />
                </div>
                <div className="ml-2">
                  {formik.values.vendor_id?.first_name}
                </div>
                <div className="ml-2 relative text-[#666666] cursor-pointer">
                  <i
                    className="fa fa-address-card-o"
                    onMouseOver={() => setIsShowDetail(true)}
                    onMouseOut={() => setIsShowDetail(false)}
                  ></i>
                  {isShowDeail && (
                    <div
                      className="absolute"
                      style={{ zIndex: 111111111, marginLeft: -28 }}
                    >
                      <div className="relative w-full h-full bg-white py-2.5 !px-5 rounded-lg mt-2.5 shadow-md">
                        <div className="flex-col flex">
                          <small className="text-small">
                            {`${formik.values.vendor_id?.first_name} ${
                              formik.values.vendor_id?.last_name
                                ? formik.values.vendor_id?.last_name
                                : ""
                            }`}
                          </small>
                          <small>Ph: {formik.values.vendor_id?.phone}</small>
                          <small>Email: {formik.values.vendor_id?.email}</small>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ml-2 text-[#666666] cursor-pointer">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="ml-2 mr-3 text-[#666666] cursor-pointer">
                  <i
                    className="fa fa-trash"
                    onClick={() => handleTech()}
                    title="Remove assigned vendor"
                  ></i>
                </div>
              </>
            )}
          </div>
          <div className="mt-3 md:!mt-2 w-full md:w-auto">
            <div className="w-full">
              <FormInput
                errors={errors}
                name="driver_notes"
                formik={formik}
                placeholder="Notes for driver"
              />
            </div>
          </div>
        </div>

        <div className="md:col-4 pl-2 !mt-4 !mb-2">
          <h5 className="">Select Technician</h5>
        </div>
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={techRecords?.map((record, index) => {
              let counter = index + offset + 1;
              const {
                _id,
                company_name,
                parent_id,
                first_name,
                last_name,
                email,
                phone,
                tech_percentage,
                zip,
                city,
                state,
                address_latitude,
                address_longitude,
                client_phone_masking,
                industry_ids,
                email_verified,
                job_count,
              } = record;
              return {
                counter,
                _id,
                company_name,
                parent_id,
                first_name,
                last_name,
                email,
                phone,
                tech_percentage,
                zip,
                city,
                state,
                address_latitude,
                address_longitude,
                client_phone_masking,
                industry_ids,
                email_verified,
                records: record,
                job_count,
              };
            })}
            totalItems={techs?.totalItems}
            searchText={searchText}
            setSearchText={setSearchText}
            searchable="Yes"
            pagination="Yes"
            isLoading={isLoader}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onFilterModelChange={onFilterChange}
            CustomToolbar={CustomToolbar}
            gridOptions={getRowHeight}
            getRowClass={getRowClassName}
            showCount="No"
            getCellClassName={getCellClassName}
          />
        </div>
        {/* <div className="panel-body1 mb-3">
          <div
            className="teamMember-drop mb-15"
            style={{ height: 300, overflow: "auto" }}
          >
            <table className="table table-bordered w-full">
              <thead
                style={{ backgroundColor: "#ededed" }}
                className="sticky top-0"
              >
                <tr>
                  <th className="border sticky">
                    <div className="justify-center flex items-center">
                      Name
                      <input
                        type="text"
                        className="!text-sm  appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-75 ml-2"
                        id="search_in_tech"
                        placeholder="Search Technician"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        value={searchText}
                      />
                    </div>
                  </th>
                  <th className="border sticky">Phone</th>
                  <th className="border sticky">City</th>
                  <th className="border sticky">State</th>
                  <th className="border sticky">ZIP</th>
                  <th className="border sticky">Distance</th>
                  <th className="border sticky">Masking</th>
                </tr>
              </thead>
              {}
              <tbody>
                {loader.getTech ? (
                  <div className="flex justify-center text-center">
                    <Loader />
                  </div>
                ) : (
                  filterTechs?.map((user, index) => {
                    const userPhone = user?.phone?.replaceAll("+1", "");
                    return (
                      <tr
                        id="tech_id_4840"
                        className="tech_tr"
                        key={index}
                        style={{
                          backgroundColor:
                            user?._id === tech?._id
                              ? "rgba(255, 183, 0, 1)"
                              : null,
                        }}
                      >
                        <td
                          onClick={() => handleTech(user?._id)}
                          style={{
                            cursor: "pointer",
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                          //
                        >
                          <div>
                            {user?._id === tech?._id ? (
                              <span className="mr-2">✓</span>
                            ) : null}
                            {user?.parent_id && user?.parent_id !== "null"
                              ? `${
                                  user?.parent_id?.first_name
                                    ? user?.parent_id?.first_name
                                    : ""
                                } ${
                                  user?.parent_id?.last_name
                                    ? user?.parent_id?.last_name
                                    : ""
                                } | `
                              : ""}
                            {user?.company_name && user?.company_name !== "null"
                              ? `${user?.company_name} | `
                              : ""}
                            {user?.first_name}{" "}
                            {user?.last_name ? user?.last_name : ""}
                          </div>
                          <div className="flex flex-wrap gap-x-2 mt-1">
                            {user?.industry_ids?.map((val, index) => {
                              return (
                                <>
                                  {val?._id && val?._id !== "null" ? (
                                    <span
                                      key={index}
                                      className={`text-sm ${
                                        formik.values.industry_id?._id ===
                                        val?._id
                                          ? "text-green-600 font-semibold text-base"
                                          : ""
                                      }`}
                                    >{`${index + 1}. ${
                                      val?.name
                                    }`}</span>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        </td>
                        <td
                          className=""
                          ref={callTech}
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          <div className="text-center flex border-b-[#fff] justify-between items-center w-[160px]">
                            {`(${userPhone?.slice(0, 3)}) ${userPhone?.slice(
                              3,
                              6
                            )}-${userPhone?.slice(6, 10)}`}
                            <FaPhone
                              color={user?._id === tech?._id ? "#fff" : "#000"}
                              className="ml-2 cursor-pointer"
                              onClick={() => {
                                setTechUserId(user?._id);
                                callOn(user?.phone);
                              }}
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          {user?.city}
                        </td>
                        <td
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          {user?.state}
                        </td>
                        <td
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          {user?.zip_to ? user?.zip_to : user?.zip}
                        </td>
                        <td
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          {calculateDistanceInMiles(
                            +formik?.values?.pickup_latitude,
                            +formik?.values?.pickup_longitude,
                            +user?.address_latitude,
                            +user?.address_longitude
                          )}
                        </td>
                        <td
                          style={{
                            color: user?._id === tech?._id ? "#fff" : null,
                          }}
                        >
                          {user?.client_phone_masking ? "Yes" : "No"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AssignTechSection;
