// InteliquentNumbers
import React, { useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch, FaRegFileExcel, FaEdit } from "react-icons/fa";
import { Button as MUIButton, TextField, IconButton } from "@mui/material";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { Button, DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import api from "../../../services/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime"; // Import the plugin
import exportReport from "../../../components/molecules/ExcelReport";
import Tooltip from "@mui/material/Tooltip";
import CustomEditCell from "./CustomEditCell";

function InteliquentNumbers() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPDomainReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/inteliquent_numbers", filter); //vishnu
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.domain && data.domain?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data?.domain.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        {/* <InputLabel>Value</InputLabel> */}
        <TextField
          value={value || ""}
          variant="standard"
          label="Filter value"
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        />
      </div>
    );
  };
  const domainCountOperators = [
    {
      label: "=",
      value: "arraySize",
      InputComponent: CustomSelectInput,
      InputComponentProps: { type: "singleSelect" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          return params.value === filterItem.value;
        };
      },
    },
  ];
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted" },
  ];
  const statusOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const CustomCell = (params) => {
    const handleEditClick = () => {
      params.api.startCellEditMode({ id: params.id, field: params.field });
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={handleEditClick}
          color="primary"
          sx={{ marginLeft: 1 }}
        >
          <FaEdit size={18} />
        </IconButton>
        <div>{params?.row?.cc_data?.did_description}</div>
      </div>
    );
  };

  const handleCellEditCommit = async (
    id,
    field,
    value,
    cellId,
    params,
    prevValue
  ) => {
    try {
      const res = await api.post("/api/test/test", {
        record_id: id,
        cc_site_name: value,
      });
      if (res.status === 200 || res.status === 201) {
        setRecord((prevData) => ({
          ...prevData,
          data: prevData.data.map((item) =>
            item._id === id ? { ...item, cc_site_name: value } : item
          ),
        }));
        params.api.stopCellEditMode({ id: cellId, field: field });
        toast.success("Row updated successfully!");
      } else {
        toast.error(res?.data?.message || "Row couldn't be updated");
        params.api.stopCellEditMode({
          id: cellId,
          field: field,
          value: prevValue,
        });
        params.api.setEditCellValue({ id: cellId, field, value: prevValue });
        params.api.commitCellChange({ id: cellId, field });
      }
    } catch (error) {
      toast.error("Failed to update row.");
      params.api.stopCellEditMode({
        id: cellId,
        field: field,
        value: prevValue,
      });
      params.api.setEditCellValue({ id: cellId, field, value: prevValue });
      params.api.commitCellChange({ id: cellId, field });
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Phone Number",
      field: "phone",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => (
        <div>
          {params?.row?.domain?.length > 0 ? (
            params.row.domain.map((item, index) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${item}`}
                className="text-[#000] hover:text-blue-600 hover:underline no-underline"
                key={index}
              >
                {item}
              </a>
            ))
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      headerName: "Connex Customer",
      field: "connex_customer",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <div>
          {params?.row?.connex_customer ? (
            <span>{params?.row?.connex_customer}</span>
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Domain Count",
      field: "domain_count",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      filterOperators: domainCountOperators,
    },
    {
      headerName: "Phone Status",
      field: "phone_status",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      headerName: "CC Site Name",
      field: "cc_data.did_description",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 250,
      editable: true,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Call Center Site Name
              </div>
            }
          >
            <span className="font-medium">CC Site Name</span>
          </Tooltip>
        </div>
      ),
      renderCell: CustomCell,
      renderEditCell: (params) => (
        <CustomEditCell
          {...params}
          onCellEditCommit={handleCellEditCommit}
          prevValue={params?.row?.cc_data?.did_description}
        />
      ),
    },
    {
      field: "domain_data.0.is_expired",
      headerName: "Expired",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => {
        const status = statusOption.find(
          (status) => status.value === params?.row?.domain_data[0]?.is_expired
        );
        return status ? status.label : "N/A";
      },
    },
    {
      field: "domain_data.0.delete_status",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => {
        const status = deleteStatusOptions.find(
          (status) =>
            status.value === params?.row?.domain_data[0]?.delete_status
        );
        return status ? status.label : "N/A";
      },
    },
    {
      headerName: "Expiration",
      field: "domain_data.0.expiration_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) =>
        new Date(params.row["domain_data.0.expiration_date"]),
      renderCell: (params) =>
        params.row.domain_data?.length ? (
          <span className="hover:underline cursor-pointer">
            {params?.row?.domain_data[0]?.expiration_date
              ? dayjs(params?.row?.domain_data[0]?.expiration_date).format(
                  "ddd, MMM D, YYYY"
                )
              : "N/A"}
          </span>
        ) : (
          <span>N/A</span>
        ),
    },
    {
      headerName: "Generated By",
      field: "domain_data.0.generate_domain_by",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        "domain_data.0.generate_domain_by": value?.value,
      }),
      valueOptions: [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
        { value: "nation", label: "National" },
      ],
      renderCell: (params) => {
        const status = [
          { value: "city", label: "City" },
          { value: "city_alias", label: "City Alias" },
          { value: "nation", label: "National" },
        ].find(
          (status) =>
            status.value === params?.row?.domain_data[0]?.generate_domain_by
        );
        return status ? status.label : "N/A";
      },
    },
  ];
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPDomainReport({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPDomainReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <div className="ml-auto -mt-2">
          <MUIButton
            variant="text"
            startIcon={<FaRegFileExcel size={16} />}
            onClick={(e) => handleExport()}
            sx={{
              borderRadius: "6px",
              marginRight: "5px",
              border: "0px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
              color: "#000",
            }}
          >
            Export Excel
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPDomainReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const syncData = async () => {
    const c = window.confirm("Are you sure want to start the process?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "https://api.logicalcrm.com/api/dataSync/start",
        {
          sync_type: "update_inteliquent_phone_nums",
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message || "Process Start successfully");
      } else if (res.status === 201) {
        toast.info(res.data.message || "Process already running");
      } else {
        toast.error(res.data.message || "Process couldn't be Started");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message || "Process couldn't be Started");
      console.log("🚀 ~ syncData ~ err:", err);
    }
  };
  const CustomButtons = () => {
    const displayTimeDifference = (endTime) => {
      const now = dayjs();
      const end = dayjs.utc(endTime); // Make sure to use utc() if your time is in UTC

      const diffHours = now.diff(end, "hour");
      const diffMinutes = now.diff(end, "minute");

      if (diffHours < 24) {
        // If the difference is less than 24 hours, but more than an hour, show "X hours ago"
        if (diffHours >= 1) {
          return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
        } else {
          // If the difference is less than an hour, show "X minutes ago"
          return diffMinutes === 1
            ? "1 minute ago"
            : `${diffMinutes} minutes ago`;
        }
      } else {
        // Otherwise, format the date
        return end.format("ddd, MMM D, YYYY h:mm A");
      }
    };

    return (
      <>
        <div className="flex flex-col items-end">
          <div className="mt-auto flex justify-end">
            <Button
              text="Sync"
              variant="btn_submit"
              onClick={syncData}
              className="w-[100px] items-end"
            />
          </div>
          {/* <span className="text-xs">
          {displayTimeDifference(record?.syncStatus?.end_time)}
        </span> */}
          <div className="text-md text-gray-700 mb-2">
            <p className="text-orange-600 font-semibold">
              {record?.syncStatus != null &&
              record?.syncStatus?.status === "InProgress"
                ? `running from ${displayTimeDifference(
                    record?.syncStatus?.start_time
                  )}`
                : ""}
              {record?.syncStatus != null &&
              record?.syncStatus?.status === "Complete"
                ? `${`Last synced on ${dayjs(record.syncStatus.end_time).format(
                    "MMM, D YYYY h:mm A"
                  )}`} `
                : ""}
            </p>
          </div>
        </div>
      </>
    );
  };
  const handleExport = () => {
    const Header = [
      "Sr",
      "Phone Number",
      "Domain",
      "Connex Customer",
      "Phone Status",
      "CC Site Name",
      "Expired",
      "Delete Status",
      "Expiration",
      "Generated By",
    ];
    const items = batchList.map((record, index) => {
      const {
        phone = " ",
        domain = [],
        phone_status = " ",
        cc_data = [],
        connex_customer = " ",
        domain_data = [],
      } = record;
      const deleteStatus = deleteStatusOptions.find(
        (status) => status.value === domain_data[0]?.delete_status
      );
      const generatedByDomain = [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
        { value: "nation", label: "National" },
      ];
      const generatedStatus = generatedByDomain.find(
        (status) => status.value === domain_data[0]?.generate_domain_by
      );
      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "N/A" : value;
      const add1IfPhoneNotEmpty = (phone) =>
        phone === undefined || phone === null || phone === "" ? "N/A" : phone;
      return {
        sr: (index + 1).toString(),
        phone: add1IfPhoneNotEmpty(phone),
        domain: domain.length ? domain.join(", ") : "N/A",
        connex_customer: addNAIfEmpty(connex_customer[0]),
        phone_status: addNAIfEmpty(phone_status),
        cc_site_name: addNAIfEmpty(cc_data?.did_description),
        expired: domain_data[0]?.is_expired ? "Yes" : "No",
        delete_status: deleteStatus?.label || "N/A",
        expiration_status: domain_data[0]?.expiration_date
          ? dayjs(domain_data[0]?.expiration_date).format("ddd, MMM D, YYYY")
          : "N/A",
        generate_domain_by: generatedStatus?.label || "N/A",
      };
    });

    exportReport(
      items,
      Header,
      "inteliquent No Report",
      "inteliquent No Report"
    );
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Inteliquent Numbers"
        CustomButtons={CustomButtons}
      />
      {isLoading || loading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const {
              _id,
              domain,
              phone_status,
              phone,
              connex_customer,
              domain_data,
              cc_data,
            } = record;
            return {
              records: { ...record },
              _id,
              counter,
              domain,
              phone,
              phone_status,
              connex_customer: connex_customer[0],
              domain_data: domain_data || [],
              cc_data: cc_data || {},
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          //   density="standard"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{
            domain_count: false,
            "domain_data.0.phone": false,
          }}
          gridOptions={getRowHeight}
          editMode="cell"
        />
      </div>
    </>
  );
}

export default InteliquentNumbers;
