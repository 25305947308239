import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaEye, FaEyeSlash, FaTrashAlt, FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { delete_user, get_users } from "../../features/usersSlice";
import { get_countries, get_states } from "../../features/countriesSlice";
import { get_timezone } from "../../features/timezoneSlice";
import { get_roles } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import Filter from "../../components/molecules/Filter";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import userPermission from "../../util/userPermission";
import SelectTaskForm from "./SelectTaskForm";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_merchants } from "../../features/merchantSlice";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import AssignIndustries from "./AssignIndustries";
import { get_industry_drd } from "../../features/IndustrySlice";
import { MdAssignmentAdd } from "react-icons/md";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { DotsLoader } from "../../components";
import { Button } from "@mui/material";
const User = () => {
  const initialBulkPayload = {
    barge: false,
    monitor: false,
    whisper: false,
    closer_campaigns: null,
  };
  const location = useLocation();
  const filterData = location.state;
  const optionsRef = React.useRef();
  const inbounds = useSelector((state) => state.inbound);
  const { isLoading, users } = useSelector((state) => state.users);
  const { record } = useSelector((state) => state.roles);
  const { timezones } = useSelector((state) => state.timezone);
  const { countries } = useSelector((state) => state.countries);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isIndustries, setIsIndustries] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const [payload, setPayload] = useState(initialBulkPayload);
  const [roleManager, setRoleManager] = useState(false);
  const roles = useSelector((state) => state.roles.record);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const dispatch = useDispatch();

  let initialValues = {
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    role_id: "",
    active: "",
  };

  const onReset = () => {
    formik.resetForm();
    // if (filterData) {
    //   navigate(location.pathname, { state: null });
    // }
    setPaginationModel({ ...paginationModel, page: 1 });
    dispatch(
      get_users({
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      user_name: Yup.string(),
      first_name: Yup.string(),
      last_name: Yup.string(),

      email: Yup.string().email("Please enter a valid email"),
      role_id: Yup.string(),
      active: Yup.boolean(),
    }),

    onSubmit: async (values) => {
      setPaginationModel({ ...paginationModel, page: 1 });
      try {
        const res = await dispatch(
          get_users({
            ...values,
            page: 1,
            size: paginationModel.pageSize,
          })
        );
        if (res?.payload?.status === 200) {
        }
      } catch (error) {
        console.error("🚀 ~ file: index.jsx:68 ~ onSubmit: ~ error:", error);
      }
    },
  });
  const activeOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  const filterForm = [
    {
      name: "user_name",
      placeholder: "Username",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "first_name",
      placeholder: "First Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "last_name",
      placeholder: "Last Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "email",
      placeholder: "Email address",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "role_id",
      placeholder: "Select Role",
      formik: formik,
      isSelect: true,
      options: record,
      onchange: (e) => formik.setFieldValue("role_id", e._id),
      getOptionLabel: (options) => options.name,
      getOptionValue: (options) => options._id,
      isVisible: true,
      value: (() => {
        const roleId = filterData?.role_id || formik?.values.role_id;
        const roleName = record?.find(({ _id }) => _id === roleId)?.name;

        return roleId ? { _id: roleId, name: roleName } : null;
      })(),
    },
    {
      name: "active",
      placeholder: "Select Status",
      formik: formik,
      isSelect: true,
      options: activeOptions,
      onchange: (e) => formik.setFieldValue("active", e.value),
      getOptionLabel: (options) => options.label,
      getOptionValue: (options) => options.value,
      isVisible: true,
      value: (() => {
        const activeValue = filterData?.active || formik?.values.active;
        return (
          activeOptions.find((option) => option.value === activeValue) || null
        );
      })(),
    },
  ];
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setRoleManager(false);
    setIsIndustries(false);
  };
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_user(record_id));
        if (res?.payload?.status === 200) {
          toast.success("User deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "User",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "User", msg: "deleted" });
      }
    }
  };
  const get_timezones = async () => {
    if (timezones?.length === 0) {
      try {
        const res = await dispatch(get_timezone());
        if (res?.payload?.status === 200) {
          if (countries?.length === 0) {
            dispatch(get_countries());
            // dispatch(get_states());
          }
        }
      } catch (error) {
        console.error("Timezones couldn't be loaded");
      }
    }
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const openIndustriesHandler = (record) => () => {
    setEditingRecord(record);
    setIsIndustries(true);
  };

  useEffect(() => {
    dispatch(
      get_users({
        ...filterData,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_roles());
    dispatch(get_merchants());
    get_timezones();
    dispatch(get_industry_drd());
    dispatch(get_inbound_group_drd());
    // eslint-disable-next-line
  }, []);
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/users/send-verification-email", {
        email: mail,
      });
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const filterUsers = users?.users?.filter((user) => {
    const searchTextLower = searchText.toLowerCase().trim();
    const userFirstNameLower = user?.first_name?.toLowerCase() || "";
    const userLastNameLower = user?.last_name?.toLowerCase() || "";
    const userEmailLower = user?.email?.toLowerCase() || "";
    const userNameLower = user?.username?.toLowerCase() || "";
    const userRoleNameLower = user?.role_id?.name?.toLowerCase() || "";
    const userPrimaryMerchantNameLower =
      user?.primary_merchant_id?.name?.toLowerCase() || "";
    const userFullNameLower = `${userFirstNameLower} ${userLastNameLower}`;
    const lastLoginIp = user?.last_login_ip?.toLowerCase() || "";
    return (
      userFullNameLower.includes(searchTextLower) ||
      userEmailLower.includes(searchTextLower) ||
      userNameLower.includes(searchTextLower) ||
      userRoleNameLower.includes(searchTextLower) ||
      lastLoginIp.includes(searchTextLower) ||
      userPrimaryMerchantNameLower.includes(searchTextLower)
    );
  });
  const testMenu = [
    { label: "Edit user", action: (e) => console.log("Edit user", e) },
    { label: "Delete User", action: () => console.log("test2") },
    { label: "Assign Industries", action: () => console.log("test3") },
    { label: "test4", action: () => console.log("test4") },
    { label: "test5", action: () => console.log("test5") },
    { label: "test6", action: () => console.log("test6") },
  ];
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (did) => did?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      setSelectedData([...users?.users]);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (user) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === user._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const dialerRoles = [
    { label: "Agent", value: 1 },
    { label: "Admin", value: 8 },
  ];
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: () => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === users?.users?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) => {
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            });
          }}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Name", field: "name" },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email_verified ? (
            <small className="text-green-600">Verified</small>
          ) : (
            <small
              className="text-red-600 hover:underline cursor-pointer ml3"
              onClick={() => sendVerificationEmail(params.row.email)}
            >
              Not verified
            </small>
          )}
        </div>
      ),
    },
    { headerName: "User Name", field: "username" },
    {
      field: "pswrd",
      headerName: "Password",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="">
            <span className="!mr-2">
              {showPassword === params.row.records?._id
                ? params.row.records.pswrd
                : "*******"}
            </span>
            {showPassword === params.row.records._id ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword("")}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword(params.row.records._id)}
              />
            )}
          </div>
        );
      },
    },
    { headerName: "Role", field: "role", width: 100 },
    { headerName: "Merchant", field: "primaryMerchant", width: 100 },
    { headerName: "Dialer User", field: "is_dialer_user", width: 100 },
    {
      headerName: "Dialer Info",
      field: "dialer_user",
      width: 100,
      renderCell: (params) => {
        return params?.row?.is_dialer_user === "Yes" ||
          params?.row?.dialer_user?.dialer_user ? (
          <div className="flex flex-col">
            <span>User: {params.row.dialer_user?.dialer_user || ""}</span>
            <span>Phone: {params.row.dialer_user?.dialer_phone || ""}</span>
            <span>
              Role:{" "}
              {dialerRoles?.find(
                ({ value }) => value === params.row.dialer_user?.dialer_role
              )?.label || ""}
            </span>
          </div>
        ) : null;
      },
    },
    {
      headerName: "Company Email",
      field: "company_email",
      width: 100,
      renderCell: (params) => {
        if (params.row.company_email) {
          return params.row.company_email;
        }
        return "N/A";
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      width: 120,
      sortable: true,
      valueGetter: (params) => params.row.createdAt, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => {
        // Convert the date strings to Date objects for comparison
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        // Compare the date objects for sorting
        return date1 - date2;
      },
    },
    { headerName: "Last Login IP", field: "lastLoginIp", width: 120 },
    { headerName: "Last Login", field: "lastLoginDate" },
    { headerName: "IP Filtering", field: "ipFiltering", width: 120 },
    { headerName: "Status", field: "status", width: 80 },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      renderCell: (params) => (
        <div>
          {userPermission("Update User") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update User"
            />
          )}
          {userPermission("Delete User") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.record_id)}
              className="my_navIcon"
              title="Delete User"
            />
          )}
          {params.row.status ? (
            <MdAssignmentAdd
              onClick={openIndustriesHandler(params.row.records)}
              className="my_navIcon"
              title="Assign Industries to this user"
              size={18}
            />
          ) : null}
          {/* <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          /> */}
          {showMenu === params.row.record_id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_users({
        ...formik.values,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };

  const bulkOptions = [
    {
      placeholder: "Select Inbounds",
      name: "closer_campaigns",
      label: "Select Inbounds",
      options: inbounds?.inboundDrd,
      valueProp: "group_id",
      labelProp: "group_name",
      isMulti: true,
      onChange: (e) => setPayload({ ...payload, closer_campaigns: e.value }),
      value: payload?.closer_campaigns,
    },
    {
      id: "barge",
      placeholder: "Barge",
      name: "barge",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, barge: !payload?.barge }),
      value: payload?.barge,
    },
    {
      id: "monitor",
      placeholder: "Monitor",
      name: "monitor",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, monitor: !payload?.monitor }),
      value: payload?.monitor,
    },
    {
      id: "whisper",
      placeholder: "Whisper",
      name: "whisper",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, whisper: !payload?.whisper }),
      value: payload?.whisper,
    },
  ];
  const update_bulk_user = async () => {
    const values = selectedData?.map(({ _id }) => ({
      user_id: _id,
      ...payload,
    }));
    try {
      const res = await api.post("/api/users/bulk_update", values);
      if (res.status === 200) {
        toast.success("Users updated successfully");
        setSelectedData([]);
        setPayload(initialBulkPayload);
      } else {
        toast.error(res.data?.message || "Users couldn't be updated");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Users couldn't be updated");
      console.log("👊 ~ constupdate_bulk_user=async ~ err:", err);
    }
  };
  const LineOne = () => {
    return (
      <div className="grid grid-cols-4 gap-2 ml-3">
        <Button
          variant="text"
          startIcon={<FaPlus size={18} />}
          onClick={addCompanyEmail}
          disabled={!selectedData.length}
          sx={{
            borderRadius: "6px",
            marginRight: "5px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Add Company Email
        </Button>{" "}
      </div>
    );
  };
  const addCompanyEmail = async () => {
    if (!selectedData?.length) {
      toast.error("Please select any User!");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (!confirmed) return;
    setIsLoader(true);
    try {
      const payload = {
        user_ids: selectedData.map((item) => item._id),
      };
      const res = await api.post(
        "/api/users/create_user_merchant_email",
        payload
      );
      if (res.status === 200 || res.status === 201) {
        toast.success(
          res?.data?.message || "Company email added successfully."
        );
      } else {
        toast.error(res.data.message || "Failed to add company email.");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while adding the company email."
      );
    } finally {
      setIsLoader(false);
      setSelectedData([]);
    }
  };
  const getRowHeight = (params) => {
    const data = params?.model;
    if (data?.is_dialer_user === "Yes" || data?.dialer_user?.dialer_user) {
      return 80;
    }
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update User" : "Add User"}
          onCancelForm={cancelFormHandler}
        />
      )}

      {isLoader ? <DotsLoader /> : null}
      {isIndustries ? (
        <AssignIndustries
          userDetail={editingRecord}
          onClose={cancelFormHandler}
        />
      ) : null}
      <PageHeader
        route="Setting > Users"
        heading="Users Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add User")}
      />
      {roleManager && (
        <SelectTaskForm
          editingRecord={editingRecord}
          modalTitle="Manage Role Permissions"
          onCancelForm={cancelFormHandler}
          users={users?.users}
        />
      )}
      <Filter
        filterForm={filterForm}
        onReset={onReset}
        formik={formik}
        className="mt-2"
        showFilter={true}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterUsers?.map((record, index) => {
            const {
              email_verified,
              merchants,
              company_email,
              is_dialer_user,
              dialer_user = {},
            } = record;
            const user = JSON.parse(localStorage.getItem("user"));
            let records = record;
            let counter = index + 1;
            let record_id = record._id;
            let name =
              record?.first_name +
              " " +
              (record?.last_name ? record?.last_name : "");
            let email = record.email;
            let username = record?.username;
            let role = roles?.find(
              ({ _id }) =>
                _id ===
                merchants.find(
                  ({ merchant_id }) => merchant_id === user?.current_merchant_id
                )?.role_id
            )?.name;
            let role_level = record?.role_id?.level;
            let primaryMerchant = record?.primary_merchant_id?.name;
            let createdAt = record.createdAt
              ? dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let lastLoginIp = record.last_login_ip;
            let lastLoginDate = record.last_login_date
              ? dayjs(record.last_login_date).format("ddd, MMM D, YYYY h:mm A")
              : "";
            let ipFiltering = record.ip_filtering ? "Yes" : "No";
            let status = record.active ? "Active" : "InActive";
            let pswrd = record.pswrd;
            return {
              counter,
              name,
              email,
              username,
              role,
              primaryMerchant,
              createdAt,
              lastLoginIp,
              lastLoginDate,
              ipFiltering,
              status,
              records,
              record_id,
              pswrd,
              role_level,
              email_verified,
              company_email,
              is_dialer_user: is_dialer_user ? "Yes" : "No",
              dialer_user,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={users.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          density="standard"
          toolbarProps={{
            isBulkUpdate: true,
            setSelectedData: setSelectedData,
            selectedItem: selectedData?.length,
            fieldsArray: bulkOptions,
            isSelectAll: selectedData?.length === users?.users?.length,
            handleBulkSave: update_bulk_user,
            onCancel: () => {
              setSelectedData([]);
              setPayload(initialBulkPayload);
            },
          }}
          LineOne2={LineOne}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default User;
