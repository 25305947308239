import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";

const CustomEditCell = (props) => {
  const { id, field, value, api, row, onCellEditCommit, prevValue } = props;
  const [editValue, setEditValue] = useState(prevValue || "");

  const handleChange = (event) => {
    setEditValue(event.target.value);
  };
  const handleSave = async () => {
    try {
      // Commit the cell change and stop edit mode
      await onCellEditCommit(row._id, field, editValue, id, props, prevValue);
      api.setEditCellValue({ id, field, value: editValue });
      api.commitCellChange({ id, field });
      api.stopCellEditMode({ id, field });
    } catch (error) {
      console.error("Save Error:", error);
      // Optionally handle error feedback to user
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        value={editValue}
        onChange={handleChange}
        sx={{ maxWidth: "200px", width: "100%" }}
      />
      <IconButton onClick={handleSave} color="primary" sx={{ marginLeft: 1 }}>
        <SaveIcon />
      </IconButton>
    </div>
  );
};

export default CustomEditCell;
